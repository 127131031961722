import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import FORM_PAGE_BLOCK_CONTENT from "./index.content";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";
import "../FormButton-secondState.scss";

const FormPageBlock = (props) => {
  const {
    isLoading,
    emailValue,
    onEmailChange,
    onSubmit,
    onError,
    onKey,
    title,
    redirectUrl,
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    customPlaceHolder,
    language = "en",
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const btnText = isAuth ? buttonTextSecondState : buttonTextFirstState;
  const { placeholder, cardInfo, agree } =
    FORM_PAGE_BLOCK_CONTENT[language] || FORM_PAGE_BLOCK_CONTENT.en;
  const placeholderText = customPlaceHolder || placeholder;
  return (
    <div className={getTrueClasses("FormPageBlock", customClass)}>
      <div className="container">
        <div className="FormPageBlock-text">
          <div className="FormPageBlock__title">{title}</div>
          {isAuth ? null : (
            <div className="FormPageBlock__cardInfo">{cardInfo}</div>
          )}
        </div>
        {isAuth ? (
          <a
            href={redirectUrl}
            target="_blank"
            className="FormPageBlock-form__button FormButton-secondStateButton"
          >
            {btnText}
          </a>
        ) : (
          <div className="FormPageBlock-formContainer">
            <form className="FormPageBlock-form">
              <input
                className={getTrueClasses(
                  "FormPageBlock-form__input",
                  onError && "FormPageBlock-form__input_alert"
                )}
                type="text"
                placeholder={onError || placeholderText}
                value={emailValue}
                onChange={onEmailChange}
                onKeyDown={onKey}
              />
              <button
                onClick={onSubmit}
                className="FormPageBlock-form__button"
                disabled={isLoading}
              >
                {btnText}
              </button>
            </form>
            <div className="FormPageBlock__agreeText">{agree}</div>
          </div>
        )}
      </div>
    </div>
  );
};

FormPageBlock.propTypes = {
  emailValue: PropTypes.string,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  customPlaceHolder: PropTypes.string,
};

export default FormPageBlock;
