import Jpg1 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic.jpg";
import Jpg1_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@2x.jpg";
import Jpg1Mob from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@mob.jpg";
import Jpg1Mob_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@mob2x.jpg";
import Webp1 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic.webp";
import Webp1_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@2x.webp";
import Webp1Mob from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@mob.webp";
import Webp1Mob_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic@mob2x.webp";

import Jpg2 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic1.jpg";
import Jpg2_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic1@2x.jpg";
import Webp2 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic1.webp";
import Webp2_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic1@2x.webp";

import Jpg3 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic2.jpg";
import Jpg3_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic2@2x.jpg";
import Webp3 from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic2.webp";
import Webp3_2x from "../../assets/images/raster/pages/amazon-sellers-bundle/common/webApp-formPic2@2x.webp";

export const PG_ASB_FORMS = {
  form1: {
    pic: Jpg1,
    pic2x: Jpg1_2x,
    picMob: Jpg1Mob,
    picMob2x: Jpg1Mob_2x,
    webp: Webp1,
    webp2x: Webp1_2x,
    webpMob: Webp1Mob,
    webpMob2x: Webp1Mob_2x,
  },
  form2: {
    pic: Jpg2,
    pic2x: Jpg2_2x,
    webp: Webp2,
    webp2x: Webp2_2x,
    title:
      "Check out the Step-by-Step Course and the Masterclasses FOR FREE NOW!",
  },
  form3: {
    pic: Jpg3,
    pic2x: Jpg3_2x,
    webp: Webp3,
    webp2x: Webp3_2x,
    title:
      "Find profitable products today with Product Database and PRO Extension FREE TRIAL!",
  },
};

export const PG_ASB_POSTS = [
  {
    title: "AMZScout is a must have...",
    description: () =>
      "AMZScout is a must have if you want to be successful in your Amazon business. AMZScout has all of the tools you need in order to find and source the right products.",
  },
  {
    title: "I absolutely love the tool...",
    description: () =>
      "I absolutely love the tool. It provides great insights into the trends and niches. I have been able to find a lot of good winners. They have great support as well. Thanks.",
  },
  {
    title: "One strength of AMZScout is...",
    description: () =>
      "One strength of AMZScout is that you can easily see how much your competitors sell and at what prices.",
  },
];

export const PG_ASB_DEALS_BANNER_CONTAINER_PROPS = {
  dealsUrl: process.env.DEALS_BUNDLE_URL,
  dealsSection: process.env.DEALS_BUNDLE_SECTION,
  dealsVersion: process.env.DEALS_BUNDLE_VERSION,
  label: "Get Holiday Deals!",
  category: "AmazonSellerBundleLP",
};
