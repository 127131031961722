import React from "react";

const LICENSE_EMAIL_COLLECT_CONTENT = {
  en: {
    placeholder: "Enter your email to start your FREE TRIAL!",
    cardInfo: "No credit card required",
    agree: <><span>No credit card required</span> By entering your email, you
      agree to receive marketing emails from AMZScout</>
  },
  cn: {
    placeholder: "输入您的电子邮件地址以开始免费试用",
    cardInfo: "无需信用卡",
    agree: "通过输入您的电子邮件地址，您同意接收来自AMZ侦探的营销电子邮件。"
  },
  de: {
    placeholder: "E-Mail eintragen, um Ihre KOSTENLOSE TESTZEIT zu starten",
    cardInfo: "Keine Kreditkarte nötig",
    agree: <>
      <span>Keine Kreditkarte nötig</span>
      <span>Mit der Eingabe Ihrer E-Mail-Adresse erklären Sie sich einverstanden, Marketing-E-Mails von AMZScout zu erhalten.</span>
    </>
  }
};

export default LICENSE_EMAIL_COLLECT_CONTENT;
