import React from "react";

import PNG_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@2x.png";
import PNG_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@1200_2x.png";
import PNG_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@992_2x.png";
import PNG_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@mob_2x.png";
import WEBP_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@2x.webp";
import WEBP_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@1200_2x.webp";
import WEBP_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@992_2x.webp";
import WEBP_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_1@mob_2x.webp";

import PNG_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@2x.png";
import PNG_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@1200_2x.png";
import PNG_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@992_2x.png";
import PNG_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@mob_2x.png";
import WEBP_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@2x.webp";
import WEBP_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@1200_2x.webp";
import WEBP_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@992_2x.webp";
import WEBP_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_2@mob_2x.webp";

import PNG_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@2x.png";
import PNG_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@1200_2x.png";
import PNG_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@992_2x.png";
import PNG_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@mob_2x.png";
import WEBP_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@2x.webp";
import WEBP_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@1200_2x.webp";
import WEBP_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@992_2x.webp";
import WEBP_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Sell/sell_3@mob_2x.webp";
import SVG_IC_GO from "../../../assets/images/vector/pages/amazon-sellers-bundle/ic_go_orange.svg";
import TrackedLink from "../../Analytics/TrackedLink";

const Link = ({ text, path }) => (
  <TrackedLink
    classes="PgASB-S-link"
    category="LANDING|amazon-sellers-bundle"
    action={text}
    path={path}
  >
    {text}
    <img src={SVG_IC_GO} alt="" />
  </TrackedLink>
);

const LINK_PROPS = {
  REVERSE_ASIN_LOOKUP: {
    text: "Reverse ASIN Lookup",
    path: "/app/#/product-keywords?r=lp_bundle_t",
  },
  AMAZON_KEYWORD_SEARCH: {
    text: "Amazon Keyword Search",
    path: "/app/#/keywords?r=lp_bundle_t",
  },
  KEYWORD_TRACKER: {
    text: "Keyword Tracker",
    path: "/app/#/keyword-tracker?r=lp_bundle_t",
  },
};

const SELL_CONTENT = [
  {
    screen: {
      PNG: PNG_One_2x,
      PNG_1200: PNG_One_1200_2x,
      PNG_992: PNG_One_992_2x,
      PNG_mob: PNG_One_mob_2x,
      WEBP: WEBP_One_2x,
      WEBP_1200: WEBP_One_1200_2x,
      WEBP_992: WEBP_One_992_2x,
      WEBP_mob: WEBP_One_mob_2x,
    },
    title:
      <>With <Link {...LINK_PROPS.REVERSE_ASIN_LOOKUP} />  you can simply enter an ASIN number and get all of the
        Keywords a product is ranked for!</>,
    list: [
      "Quickly see all of the keywords that competitors’ products rank for. Copy the list and catch up with already successful sellers faster.",
      "Take the keywords that people use to describe products like yours and make a listing that buyers will easily find through Amazon search",
      "Reverse ASIN Lookup will also show you how often each keyword is looked for so that you will know how many people looked for this item in the last month",
    ],
  },
  {
    screen: {
      PNG: PNG_Two_2x,
      PNG_1200: PNG_Two_1200_2x,
      PNG_992: PNG_Two_992_2x,
      PNG_mob: PNG_Two_mob_2x,
      WEBP: WEBP_Two_2x,
      WEBP_1200: WEBP_Two_1200_2x,
      WEBP_992: WEBP_Two_992_2x,
      WEBP_mob: WEBP_Two_mob_2x,
    },
    title: <>Get thousands of relevant keywords for your product with <Link {...LINK_PROPS.AMAZON_KEYWORD_SEARCH} /></>,
    list: [
      "Maximize the chance of finding your product by adding all possible words and phrases people could use to search for your product",
      "Try new keywords for PPC ads to find the ones that give you the most buyers for your dollar",
    ],
  },
  {
    screen: {
      PNG: PNG_Three_2x,
      PNG_1200: PNG_Three_1200_2x,
      PNG_992: PNG_Three_992_2x,
      PNG_mob: PNG_Three_mob_2x,
      WEBP: WEBP_Three_2x,
      WEBP_1200: WEBP_Three_1200_2x,
      WEBP_992: WEBP_Three_992_2x,
      WEBP_mob: WEBP_Three_mob_2x,
    },
    title: <>Get even more sales from your ad budget by precisely monitoring how you rank on advertised keywords with
      the <Link {...LINK_PROPS.KEYWORD_TRACKER} /></>,
    list: [
      "Know when a paid keyword has raised your organic ranking to its full potential and it’s time to stop spending on it",
      "Spot useless keywords that eat up money but don’t rank your product higher and cut them off",
      "See your competitors’ activity and respond if they try to outrank you on crucial keywords",
    ],
    text: "Simply add a product to the Keyword Tracker using its ASIN, select the keywords that you’d like to track and see how well your product ranks",
  },
];

export default SELL_CONTENT;
