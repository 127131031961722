import React from "react";
import "./index.scss";

const LastSection = () => (
  <section className="PgASB-LastSection">
    <div className="container">
      <h2 className="PgASB-LastSection__title">
        As you can see AMZScout offers a complete Amazon selling tools suite
      </h2>
      <div className="PgASB-LastSection__subtitle">
        with product finder software for analytics, listing optimization,
        marketing and competitor analysis
      </div>
      <div className="PgASB-LastSection__text">
        Try this toolkit for Amazon business with a marketplace scanner and
        analyzer to find the best niche to sell in and choose competitive
        pricing for your product!
      </div>
    </div>
  </section>
);

export default LastSection;
