import React from "react";
import StarIcon from "../../../assets/images/vector/components/ChromeRating/new_star.svg";
import HalfStarIcon from "../../../assets/images/vector/components/ChromeRating/new_star_half.svg";
import CHROME_RATING_CONTENT from "./index.content";
import { getTrueClasses } from "../../../helpers";
import "./index.scss";

const ChromeRating = ({ langCode = "en" }) => {
  const { info, note } = CHROME_RATING_CONTENT[langCode];
  return (
    <div
      className={getTrueClasses(
        "GC-CR",
        langCode !== "en" && `GC-CR_${langCode}`
      )}
    >
      <div className="container">
        <div className="GC-CR__wrapper">
          <div className="GC-CR__stars">
            <img src={StarIcon} alt="*" loading="lazy" />
            <img src={StarIcon} alt="*" loading="lazy" />
            <img src={StarIcon} alt="*" loading="lazy" />
            <img src={StarIcon} alt="*" loading="lazy" />
            <img src={HalfStarIcon} alt="*" loading="lazy" />
          </div>
          <span className="GC-CR__note">{note}</span>
          <span className="GC-CR__info">{info}</span>
        </div>
      </div>
    </div>
  );
};

export default ChromeRating;
