import React from "react";
import { getTrueClasses } from "../../../helpers";
import VideoTestimonials from "../VideoTestimonials";
import "./index.scss";

const TestimonialsSection = (props) => {
  const { setUrlYoutubeModal, isH1, title, customClass } = props;
  return (
    <section className={getTrueClasses("GC-TS", customClass)}>
      {isH1 ? (
        <h1 className="GC-TS__title">{title}</h1>
      ) : (
        <h2 className="GC-TS__title">{title}</h2>
      )}
      <div className="GC-TS__subtitle">
        Empowering famous sellers and AMZScout residents
      </div>
      <VideoTestimonials setUrlYoutubeModal={setUrlYoutubeModal} />
    </section>
  );
};

export default TestimonialsSection;
