import React from "react";
import SELL_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const Sell = () => (
  <section className="PgASB-S">
    <div className="container">
      <h2 className="PgASB-S__title">Sell your product </h2>
      <p className="PgASB-S__subtitle">
        With a listing that is easy to find for buyers and cost-efficient
        pay-per-click ads
      </p>
      <div className="PgASB-S-list">
        {SELL_CONTENT.map(({ screen, title, list, text }, i) => (
          <div key={i} className={`PgASB-S-row PgASB-S-row-r${i + 1}`}>
            <div className="PgASB-S-info">
              <p className="PgASB-S-info__title">{title}</p>
              <ul className="PgASB-S-info-list">
                {list.map((item) => (
                  <li key={item} className="PgASB-S-info__item">
                    {item}
                  </li>
                ))}
              </ul>
              {text && <div className="PgASB-S-info__text">{text}</div>}
            </div>
            <Screen {...screen} i={i} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Sell;
