const CHROME_RATING_CONTENT = {
  en: {
    note: "Chrome Store Rating | 500,000 users",
    info: "over 5 million profitable products found"
  },
  ja: {
    note: "Chromeストアの評価 | 500,000ユーザー",
    info: "500万を超える収益性の高い製品が見つかりました"
  },
  cn: {
    note: "Chrome网上应用商店评分 | 50万用户",
    info: "已有1,000,000种高利润产品"
  },
  de: {
    note: "Chrome Shop Bewertung | 500,000 Nutzer",
    info: "Über 5 Millionen profitable Produkte gefunden"
  }
};

export default CHROME_RATING_CONTENT;