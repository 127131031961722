import React from "react";
import PARTNER_BRAND_CONTENT from "./index.content";
import "./index.scss";

const PartnerBrand = () => {
  const { dataList } = PARTNER_BRAND_CONTENT;
  return (
    <div className="GC-PB">
      <div className="container">
        <div className="GC-PB__wrapper">
          {dataList.map(({ name, icon }, i) => (
            <div key={i}>
              <img
                className={`GC-PB__${name} GC-PB__img`}
                src={icon}
                alt=""
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerBrand;
