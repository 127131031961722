import React from "react";
import FIND_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const Find = () => {
  return (
    <section className="PgASB-F">
      <h2 className="PgASB-F__title">Find the right product</h2>
      <div className="PgASB-F-list">
        {FIND_CONTENT.map(({ screen, list, title, text, boldText }, i) => (
          <div key={i} className={`PgASB-F-row PgASB-F-row_r${i + 1}`}>
            <div className="PgASB-F-content">
              <div className="PgASB-F-info">
                <p className="PgASB-F-info__title">{title}</p>
                <ul className="PgASB-F-info-list">
                  {list.map((item) => (
                    <li key={item} className="PgASB-F-info__item">
                      {item}
                    </li>
                  ))}
                </ul>
                {boldText && (
                  <p className="PgASB-F-info__boldText">{boldText}</p>
                )}
                {text && <p className="PgASB-F-info__text">{text}</p>}
              </div>
              <Screen {...screen} i={i} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Find;
