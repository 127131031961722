import PNG_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@2x.png";
import PNG_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@1200_2x.png";
import PNG_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@992_2x.png";
import PNG_One_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@768_2x.png";
import PNG_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@mob_2x.png";

import WEBP_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@2x.webp";
import WEBP_One_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@1200_2x.webp";
import WEBP_One_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@992_2x.webp";
import WEBP_One_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@768_2x.webp";
import WEBP_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_1@mob_2x.webp";

import PNG_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@2x.png";
import PNG_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@1200_2x.png";
import PNG_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@992_2x.png";
import PNG_Two_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@768_2x.png";
import PNG_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@mob_2x.png";

import WEBP_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@2x.webp";
import WEBP_Two_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@1200_2x.webp";
import WEBP_Two_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@992_2x.webp";
import WEBP_Two_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@768_2x.webp";
import WEBP_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_2@mob_2x.webp";

import PNG_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@2x.png";
import PNG_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@1200_2x.png";
import PNG_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@992_2x.png";
import PNG_Three_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@768_2x.png";
import PNG_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@mob_2x.png";

import WEBP_Three_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@2x.webp";
import WEBP_Three_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@1200_2x.webp";
import WEBP_Three_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@992_2x.webp";
import WEBP_Three_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@768_2x.webp";
import WEBP_Three_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_3@mob_2x.webp";

import PNG_Four_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@2x.png";
import PNG_Four_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@1200_2x.png";
import PNG_Four_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@992_2x.png";
import PNG_Four_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@768_2x.png";
import PNG_Four_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@mob_2x.png";

import WEBP_Four_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@2x.webp";
import WEBP_Four_1200_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@1200_2x.webp";
import WEBP_Four_992_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@992_2x.webp";
import WEBP_Four_768_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@768_2x.webp";
import WEBP_Four_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/Tools/tools_4@mob_2x.webp";

const LINK_PROPS = {
  READY_TO_SELL_AMAZON_PRODUCTS: {
    text: "1,000+ Ready-to-Sell Amazon Products",
    path: "/app/#/reports/insights?r=lp_bundle_t",
  },
  MONTHLY_AMAZON_PRODUCT_TREND_REPORTS: {
    text: "Monthly Amazon Product Trend Reports",
    path: "/app/#/reports/reports?r=lp_bundle_t",
  },
  PRODUCT_DATABASE: {
    text: "Product Database",
    path: "/app/#/database?r=lp_bundle_t",
  },
  PRO_EXTENSION: {
    text: "PRO Extension",
    path: `${process.env.SCOUT_EXT_PRO}?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_t`,
    showMobileProPopup: true,
  },
  PRODUCT_TRACKER: {
    text: "Product Tracker",
    path: "/app/#/tracker?r=lp_bundle_t",
  },
  QUICK_VIEW: {
    text: "Quick View",
    path: "https://chromewebstore.google.com/detail/amazon-quick-view-by-amzs/pggamokfileohlopdonjmelbbghhnlah?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_t",
  },
  STOCK_STATS: {
    text: "Stock Stats",
    path: "https://chromewebstore.google.com/detail/amzscout-stock-stats-amaz/liobflkelkokkacdemhmgkbpefgaekkm?utm_source=site&utm_medium=amzscout&utm_campaign=lp_bundle_t",
  },
  REVERSE_ASIN_LOOKUP: {
    text: "Reverse ASIN Lookup",
    path: "/app/#/product-keywords?r=lp_bundle_t",
  },
  AMAZON_KEYWORD_SEARCH: {
    text: "Amazon Keyword Search",
    path: "/app/#/keywords?r=lp_bundle_t",
  },
  KEYWORD_TRACKER: {
    text: "Keyword Tracker",
    path: "/app/#/keyword-tracker?r=lp_bundle_t",
  },
  STEP_BY_STEP_COURSE: {
    text: "Step-by-Step Course",
    path: "/amazon-private-label-course?r=lp_bundle_t",
  },
  FIGURE_SELLER_MASTERCLASSES: {
    text: "7-figure Seller Masterclasses",
    path: "/free-amazon-fba-masterclasses?r=lp_bundle_t",
  },
};

const TOOLS = [
  {
    screen: {
      PNG: PNG_One_2x,
      PNG_1200: PNG_One_1200_2x,
      PNG_992: PNG_One_992_2x,
      PNG_768: PNG_One_768_2x,
      PNG_mob: PNG_One_mob_2x,
      WEBP: WEBP_One_2x,
      WEBP_1200: WEBP_One_1200_2x,
      WEBP_992: WEBP_One_992_2x,
      WEBP_768: WEBP_One_768_2x,
      WEBP_mob: WEBP_One_mob_2x,
    },
    title: "1. One-in-a-Million Potential Bestsellers to Launch on Amazon",
    list: [
      {
        link: LINK_PROPS.READY_TO_SELL_AMAZON_PRODUCTS,
        text: "Receive 19 high-demand products that have the potential to become a huge bestseller, yet still have low competition. These products are selected by AI out of 600+ million products as the best opportunities each week."
      },
      {
        link: LINK_PROPS.MONTHLY_AMAZON_PRODUCT_TREND_REPORTS,
        text: "Receive reports with monthly product trends to know which categories are becoming more profitable and which ones should be avoided. Check out profitable niches, Amazon news and rules updates,  and read tips on maximizing your profit!"
      },
    ],
  },
  {
    screen: {
      PNG: PNG_Two_2x,
      PNG_1200: PNG_Two_1200_2x,
      PNG_992: PNG_Two_992_2x,
      PNG_768: PNG_Two_768_2x,
      PNG_mob: PNG_Two_mob_2x,
      WEBP: WEBP_Two_2x,
      WEBP_1200: WEBP_Two_1200_2x,
      WEBP_992: WEBP_Two_992_2x,
      WEBP_768: WEBP_Two_768_2x,
      WEBP_mob: WEBP_Two_mob_2x,
    },
    title: "2. Product Research Tools to find $9,000+/mo GEMS",
    list: [
      {
        link: LINK_PROPS.PRODUCT_DATABASE,
        text: "Find profitable products to sell on Amazon by filtering through millions of products in just a few clicks!"
      },
      {
        link: LINK_PROPS.PRO_EXTENSION,
        text: "Legendary product research tool that lets you quickly get an estimation of product potential and competition level based on solid sales stats. It also provides sales history and other data for a thorough product check before you invest in it."
      },
      {
        link: LINK_PROPS.PRODUCT_TRACKER,
        text: "Record everyday product performance to evaluate product potential and keep eye on your competition"
      },
      {
        link: LINK_PROPS.QUICK_VIEW,
        text: "Don’t miss great products when simply browsing Amazon. See product sales data as if you were the seller of every item instantly, right on the Amazon page."
      },
      {
        link: LINK_PROPS.STOCK_STATS,
        text: "See your competitors inventory quantity in real life to track their sales. Catch their “out of stock” moments to seize more sales and make a higher margin."
      },
    ],
  },
  {
    screen: {
      PNG: PNG_Three_2x,
      PNG_1200: PNG_Three_1200_2x,
      PNG_992: PNG_Three_992_2x,
      PNG_768: PNG_Three_768_2x,
      PNG_mob: PNG_Three_mob_2x,
      WEBP: WEBP_Three_2x,
      WEBP_1200: WEBP_Three_1200_2x,
      WEBP_992: WEBP_Three_992_2x,
      WEBP_768: WEBP_Three_768_2x,
      WEBP_mob: WEBP_Three_mob_2x,
    },
    title: "3. Keyword Research Tools to market your product",
    list: [
      {
        link: LINK_PROPS.REVERSE_ASIN_LOOKUP,
        text: "Enter an ASIN number and receive all of the Keywords a product is ranked for! This way, you can estimate product demand by how often it is searched for or copy existing keywords to catch up to your competitors."
      },
      {
        link: LINK_PROPS.AMAZON_KEYWORD_SEARCH,
        text: "Find thousands of relevant keywords for your product listing to maximize customer flow from Amazon search results"
      },
      {
        link: LINK_PROPS.KEYWORD_TRACKER,
        text: "Get the most sales from your ad budget by precisely monitoring how you rank on advertised keywords!"
      },
    ],
  },
  {
    screen: {
      PNG: PNG_Four_2x,
      PNG_1200: PNG_Four_1200_2x,
      PNG_992: PNG_Four_992_2x,
      PNG_768: PNG_Four_768_2x,
      PNG_mob: PNG_Four_mob_2x,
      WEBP: WEBP_Four_2x,
      WEBP_1200: WEBP_Four_1200_2x,
      WEBP_992: WEBP_Four_992_2x,
      WEBP_768: WEBP_Four_768_2x,
      WEBP_mob: WEBP_Four_mob_2x,
    },
    title: "4. The knowledge you need to get your sales going",
    list: [
      {
        link: LINK_PROPS.STEP_BY_STEP_COURSE,
        text: "Learn how to find, source, market, and sell a product on Amazon"
      },
      {
        link: LINK_PROPS.FIGURE_SELLER_MASTERCLASSES,
        text: "Take a deeper look at different aspects of product research and selling with already successful sellers"
      },
    ],
  },
]

export default TOOLS
