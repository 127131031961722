import React, { useContext } from "react";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import PropTypes from "prop-types";
import FormBlockContainer from "../../containers/common/forms/FormBlockContainer";
import { PAGE_OPTIONS, PAGES } from "../../helpers/constants";
import { getTrueClasses } from "../../helpers";

const FormWithPicture = (props) => {
  const {
    content: { jpeg, jpeg2x, webp, webp2x, title },
    customClass,
    formRef,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);

  return (
    <section
      ref={formRef}
      className={getTrueClasses(
        "PgASB-FormWithPicture",
        customClass,
        isAuth && "PgASB-FormWithPicture-secondState"
      )}
    >
      <div className="PgASB-FormWithPicture__img">
        <picture>
          <source type="image/webp" srcSet={`${webp} 1x, ${webp2x} 2x`} />
          <img srcSet={`${jpeg} 1x, ${jpeg2x} 2x`} alt="" />
        </picture>
      </div>
      <FormBlockContainer
        page={PAGES.AMAZON_SELLERS_BUNDLE}
        title={title}
        buttonTextFirstState="Try AMZScout for free!"
        buttonTextSecondState="Try AMZScout for free!"
        redirectUrl={PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL}
      />
    </section>
  );
};

FormWithPicture.propTypes = {
  content: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FormWithPicture;
