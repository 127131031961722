import React from "react";

const Screen = ({ JPG, JPG_MOB, i }) => (
  <picture>
    <source media="(min-width: 768px)" srcSet={JPG} />
    <source media="(max-width: 767px)" srcSet={JPG_MOB} />
    <img
      className={`PgASB-KH-S PgASB-KH-S_s${i + 1}`}
      src={JPG}
      alt=""
      loading="lazy"
    />
  </picture>
);

export default Screen;
