import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import Close from "./Close";
import "./index.scss";

const DealsBundle = (props) => {
  const { isShowDeals, hideCloseButton, clickOpenHandler, isBottomLine } =
    props;
  if (!isShowDeals) {
    return null;
  }
  return (
    <div
      className={getTrueClasses(
        "PgASB-DealsBundle-section",
        "PgASB-DealsBundle-section_fixed",
        isBottomLine && "PgASB-DealsBundle-section_stay"
      )}
    >
      <div className="PgASB-DealsBundle-container">
        <div className="PgASB-DealsBundle-content" onClick={clickOpenHandler}>
          <div className="PgASB-DealsBundle__title">
            <p>
              Discover the New <span>All-in-One Amazon Toolkit</span>
            </p>
            <p>for Dropshipping, Online Arbitrage, and Wholesale Sellers</p>
          </div>
          <button className="PgASB-DealsBundle__cta elem__btn elem__btn_orange">
            SEE DETAILS
          </button>
          <Close hideCloseButton={hideCloseButton} />
        </div>
      </div>
    </div>
  );
};
DealsBundle.propTypes = {
  isShowDeals: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  clickOpenHandler: PropTypes.func,
};
export default DealsBundle;
