import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import TrustPilotIcon from "../../../assets/images/vector/components/RatingReview/trustpilot_logo.svg";
import SvgRating from "../../../assets/images/vector/components/RatingReview/RatingReviewTrust.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

export const RatingReview = (props) => {
  const { posts, customClass, excellentText, trustText } = props;
  const { media: { isNotInitMobile } } = useContext(MediaAuthContext);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const settings = {
    className: "PgASB-RatingReview-slider",
    centerMode: true,
    infinite: false,
    arrows: false,
    initialSlide: 0,
    speed: 500,
    touchThreshold: 40,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  return (
    <section className={`PgASB-RatingReview ${customClass || ""}`}>
      <div className="container">
        <div className="PgASB-RatingReview-row">
          {isNotInitMobile && isRendered ? (
            <Slider {...settings}>
              <RatingReviewTrust
                excellentText={excellentText}
                trustText={trustText}
              />
              {posts.map(({ title, description, descriptionLong }) => (
                <RatingReviewPost
                  key={title}
                  title={title}
                  description={description}
                  descriptionLong={descriptionLong}
                />
              ))}
            </Slider>
          ) : (
            <>
              <RatingReviewTrust
                excellentText={excellentText}
                trustText={trustText}
              />
              <RatingReviewPostList posts={posts} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default RatingReview;

const RatingReviewTrust = (props) => {
  const { excellentText, trustText } = props;
  return (
    <div className="PgASB-RatingReview-trust">
      <img src={TrustPilotIcon} alt="" />
      <div className="PgASB-RatingReview-trust-value">
        <span>‘</span>
        {!excellentText ? "Excellent" : excellentText}
        <span>’</span>
      </div>
      {trustText ? (
        <div className="PgASB-RatingReview-trust-text">{trustText}</div>
      ) : null}
    </div>
  );
};
const RatingReviewPostList = ({ posts }) =>
  posts.map(({ title, description, descriptionLong }) => (
    <RatingReviewPost
      key={title}
      title={title}
      description={description}
      descriptionLong={descriptionLong}
    />
  ));
const RatingReviewPost = ({ title, description, descriptionLong }) => (
  <div key={title} className="PgASB-RatingReview-post">
    <img src={SvgRating} alt="" className="PgASB-RatingReview-post__stars" />
    <p className="PgASB-RatingReview-post__title">{title}</p>
    <div className="PgASB-RatingReview-post__info">
      {description()}
      {descriptionLong ? <div className="link">read more</div> : null}
    </div>
  </div>
);
