import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import RedirectedButton from "../emailCollectRedirectedButton";
import { getTrueClasses } from "../../../../helpers";
import LICENSE_EMAIL_COLLECT_CONTENT from "./index.content";
import "../StandardEmailCollect/index.scss";

const LicenseEmailCollect = ({
  isShowText,
  pageLoading,
  buttonTextSecondState,
  redirectUrl,
  buttonTextFirstState,
  customClass,
  onError,
  emailValue,
  isLoading,
  onEmailChange,
  onKey,
  onSubmit,
  language = "en",
  customPlaceholder,
  formOpacity,
}) => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const { placeholder, cardInfo, agree } =
    LICENSE_EMAIL_COLLECT_CONTENT[language] || LICENSE_EMAIL_COLLECT_CONTENT.en;
  const classes = getTrueClasses(
    "emailView",
    "emailCollect",
    "emailCollect_green",
    "emailCollect_mic",
    customClass
  );
  const titleClasses = getTrueClasses(
    "emailCollect__title",
    "emailCollect__title_mic",
    language === "cn" && "emailCollect__title_grid"
  );
  const placeholderText = customPlaceholder || placeholder;
  return !isAuth ? (
    <div className={classes} style={{ opacity: formOpacity }}>
      <div className="emailCollect__container emailCollect__container_mic">
        <div className="emailCollect-form emailCollect-form_green">
          <p className={titleClasses}>
            <span>{cardInfo}</span>
          </p>
          <div className="emailCollect__control emailCollect__control_mic">
            {/* <input type="email" placeholder={!onError ? "Enter your email to start your FREE TRIAL!" : onError}*/}
            <input
              type="email"
              placeholder={onError || placeholderText}
              className={getTrueClasses(
                "emailCollect__input",
                "emailCollect__input_green",
                onError && "error"
              )}
              value={emailValue}
              onChange={onEmailChange}
              onKeyDown={onKey}
            />
            <button
              type="button"
              className="bt emailCollect__bt emailCollect__bt_green"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {buttonTextFirstState}
            </button>
          </div>
          <p className="emailCollect__info emailCollect__info_mic">{agree}</p>
        </div>
      </div>
    </div>
  ) : (
    <RedirectedButton
      href={redirectUrl}
      title={buttonTextSecondState}
      isIntro
      isTopText={false}
      isShowText={isShowText}
      customClass={getTrueClasses(
        language === "cn" && "emailCollect__cnRedirectedButton"
      )}
      isLoading={pageLoading}
    />
  );
};

LicenseEmailCollect.propTypes = {
  isSecondState: PropTypes.bool,
  setIsSecondState: PropTypes.func,
  title: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  buttonTextSecondState: PropTypes.string,
  redirectUrl: PropTypes.string,
  customClass: PropTypes.string,
  isShowText: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool,
  onError: PropTypes.string,
  emailValue: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onKey: PropTypes.func,
  onSubmit: PropTypes.func,
  isOpacity: PropTypes.bool,
  customPlaceholder: PropTypes.string,
};

export default LicenseEmailCollect;
