import React from "react";

const Screen = (props) => {
  const {
    PNG,
    PNG_1200,
    PNG_992,
    PNG_mob,
    WEBP,
    WEBP_1200,
    WEBP_992,
    WEBP_mob,
    i,
  } = props;
  return (
    <div className={`PgASB-F-S PgASB-F-S_s${i + 1}`}>
      <picture>
        <source type="image/webp" media="(min-width: 1366px)" srcSet={WEBP} />
        <source media="(min-width: 1366px)" srcSet={PNG} />
        <source
          type="image/webp"
          media="(min-width: 1200px) and (max-width: 1365px)"
          srcSet={WEBP_1200}
        />
        <source
          media="(min-width: 1200px) and (max-width: 1365px)"
          srcSet={PNG_1200}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 1199px)"
          srcSet={WEBP_992}
        />
        <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcSet={PNG_992}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={WEBP_mob}
        />
        <source media="(max-width: 767px)" srcSet={PNG_mob} />
        <img className="PgASB-F-S__img" src={PNG} alt="" loading="lazy" />
      </picture>
    </div>
  );
};

export default Screen;
