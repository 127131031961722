import React from "react";
import KNOW_HOW_CONTENT from "./index.content";
import Screen from "./Screen";
import "./index.scss";

const KnowHow = () => {
  return (
    <section className="PgASB-KH">
      <h2 className="PgASB-KH__title">Know how to sell on Amazon</h2>
      <div className="PgASB-KH-content">
        {KNOW_HOW_CONTENT.map(({ title, list, text, screen }, i) => (
          <div key={i} className={`PgASB-KH-block PgASB-KH-block_b${i + 1}`}>
            <div className="PgASB-KH-info">
              {title && <p className="PgASB-KH-info__title">{title}</p>}
              <ul className="PgASB-KH-info-list">
                {list.map((item) => (
                  <li key={item} className="PgASB-KH-info__item">
                    {item}
                  </li>
                ))}
              </ul>
              <p className="PgASB-KH-info__text">{text}</p>
            </div>
            <Screen {...screen} i={i} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default KnowHow;
