import JPG_One_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/KnowHow/know_how_1@2x.jpg";
import JPG_One_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/KnowHow/know_how_1@mob_2x.jpg";

import JPG_Two_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/KnowHow/know_how_2@2x.jpg";
import JPG_Two_mob_2x from "../../../assets/images/raster/pages/amazon-sellers-bundle/KnowHow/know_how_2@mob_2x.jpg";
import React from "react";


const KNOW_HOW_CONTENT = [
  {
    title: "Learn how to:",
    list: [
      "select products",
      "find and negotiate with suppliers",
      "create your seller’s account",
      "and much more...",
    ],
    text: <>with <b>23 educational videos</b> from <b>our Step-by-Step Course</b></>,
    screen: {
      JPG: JPG_One_2x,
      JPG_MOb: JPG_One_mob_2x,
    },
  },
  {
    list: [
      "Discover which model of selling on Amazon would work best for you, based on your starting budget and preferences",
      "Learn tips and strategies for picking the most profitable products",
      "Dive deeper into specifics of seller’s tasks like making a listing that sells",
    ],
    text: <>with <b>34 masterclasses</b> from <b>7-figure sellers!</b></>,
    screen: {
      JPG: JPG_Two_2x,
      JPG_MOb: JPG_Two_mob_2x,
    },
  },
];
export default KNOW_HOW_CONTENT;
