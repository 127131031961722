import React, { useState, useEffect, useRef, useContext } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import {
  PG_ASB_DEALS_BANNER_CONTAINER_PROPS,
  PG_ASB_FORMS,
  PG_ASB_POSTS,
} from "../components/amazon-sellers-bundle/index.content";
import { PAGE_OPTIONS, PAGES } from "../helpers/constants";
import { getTrueClasses } from "../helpers";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import HomeIntro from "../components/amazon-sellers-bundle/HomeIntro";
import ChromeRating from "../components/common/ChromeRating";
import PartnerBrand from "../components/common/PartnerBrand";
import RatingReview from "../components/amazon-sellers-bundle/RatingReview";
import Testimonials from "../components/common/TestimonialsSection";
import Tools from "../components/amazon-sellers-bundle/Tools";
import LastSection from "../components/amazon-sellers-bundle/LastSection";
import KnowHow from "../components/amazon-sellers-bundle/KnowHow";
import FindSection from "../components/amazon-sellers-bundle/Find";
import CtaSection from "../components/amazon-sellers-bundle/CtaSection";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import Sell from "../components/amazon-sellers-bundle/Sell";
import FormWithPicture from "../components/amazon-sellers-bundle/FormWithPicture";
import DealsBundle from "../components/amazon-sellers-bundle/DealsBundle";
// import SimpleDeals from "../components/common/SimpleDeals";
// import DealsStaticBannerContainer from "../containers/common/DealsStaticBannerContainer";
import DealsBannerContainer from "../containers/common/DealsBannerContainer";
import EmptySpace from "../components/amazon-sellers-bundle/EmptySpace";
import "../components/amazon-sellers-bundle/index.scss";

const PgASB = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);

  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  // const [isShowStaticBanner, setIsShowStaticBanner] = useState(true);
  const [isShowEmptySpace, setIsShowEmptySpace] = useState(true);

  const homeIntroRef = useRef(null);
  const tariffs = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  const closeYoutubeModal = () => setUrlYoutubeModal("");

  const handleClickOneMiddleForm = () => {
    if (isAuth) {
      window.open(
        PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL,
        "_blank"
      );
    } else {
      formRef?.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Layout
      urlYoutubeModal={urlYoutubeModal}
      tariffsElement={tariffs}
      isShowYoutubeModal={isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      isBundlePanel
      // isSimpleTopPanel
      customClass="PgASB"
    >
      <Seo
        title={`Top 12 Amazon Seller Tools for ${process.env.YEAR}`}
        description="Must-have Amazon seller tools for FBA and FBM sellers: 1. Step-by-Step Course · 2. Product Research Toolkit · 3. Keyword Tools (Keyword Search, ASIN Lookup)"
        page="amazon-sellers-bundle"
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <HomeIntro homeIntroRef={homeIntroRef} />
      {/* <DealsStaticBannerContainer*/}
      {/*  isShowStaticBanner={isShowStaticBanner}*/}
      {/*  setIsShowStaticBanner={setIsShowStaticBanner}*/}
      {/*  homeIntroRef={homeIntroRef}*/}
      {/*  {...PG_ASB_DEALS_BANNER_CONTAINER_PROPS}*/}
      {/* >*/}
      {/*  <SimpleDeals hideCloseButton isStaticDeals />*/}
      {/* </DealsStaticBannerContainer>*/}
      <ChromeRating />
      <PartnerBrand />
      <RatingReview
        isMainPage
        excellentText="Excellent"
        trustText="Based on Trustpilot reviews"
        posts={PG_ASB_POSTS}
      />
      <Tools />
      <CtaSection handleClick={handleClickOneMiddleForm} />
      <CustomizableContainer
        pricingRef={tariffs}
        customClass="PgASB-pricing"
        subTitlePro
      />
      <KnowHow />
      <FormWithPicture
        content={PG_ASB_FORMS.form2}
        customClass="PgASB-FormWithPicture-one"
        formRef={formRef}
      />
      <FindSection />
      <FormWithPicture
        content={PG_ASB_FORMS.form3}
        customClass="PgASB-FormWithPicture-two"
      />
      <Sell />
      <FormBlockContainer
        page={PAGES.AMAZON_SELLERS_BUNDLE}
        title="Enter your email to start your FREE TRIAL!"
        buttonTextFirstState="Try AMZScout for free!"
        buttonTextSecondState="Try AMZScout for free!"
        redirectUrl={PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL}
        customClass={getTrueClasses(
          "PgASB-bottomForm",
          isAuth && "PgASB-bottomForm-secondState"
        )}
      />
      <Testimonials
        setUrlYoutubeModal={setUrlYoutubeModal}
        title="Empowering famous sellers and AMZScout residents"
        customClass="PgASB-testimonials"
      />
      <LastSection />
      <EmptySpace
        isShow={process.env.DEALS_BUNDLE_SECTION === "true" && isShowEmptySpace}
        isDealsBundle
      />
      <DealsBannerContainer
        setIsShowEmptySpace={setIsShowEmptySpace}
        // alwaysShowDeals
        // multipleBannerOptions
        // isShowStaticBanner={isShowStaticBanner}
        {...PG_ASB_DEALS_BANNER_CONTAINER_PROPS}
      >
        {/* <SimpleDeals hideCloseButton />*/}
        <DealsBundle />
      </DealsBannerContainer>
    </Layout>
  );
};

export default PgASB;
