import React from "react";
import POINT_ICON from "../../../../assets/images/vector/components/common/group-copy.svg";
import "./index.scss";

const POINTS_TEXT = [
  "Learn all of the steps of selling on Amazon",
  "Find profitable products",
  "Sell with a perfect product listing and the right keywords for paid ads with AMZScout web app tools",
];

const Points = () => (
  <ul className="PgASB-HI-P">
    {POINTS_TEXT.map((text, i) => (
      <li key={i} className="PgASB-HI-P__item">
        <img src={POINT_ICON} alt="" />
        <div>{text}</div>
      </li>
    ))}
  </ul>
);

export default Points;
