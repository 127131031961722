import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import "./index.scss";
import "../FormButton-secondState.scss";

const FormIntroBlock = (props) => {
  const {
    isLoading,
    emailValue,
    onEmailChange,
    onSubmit,
    onError,
    onKey,
    title,
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    redirectUrl,
    placeholder,
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const btnText = isAuth ? buttonTextSecondState : buttonTextFirstState;

  return (
    <div className={`FormIntroBlock ${customClass || ""}`}>
      <div className="FormIntroBlock__title">{title}</div>
      {isAuth ? (
        <a
          href={redirectUrl}
          target="_blank"
          className="FormIntroBlock-form__button FormButton-secondStateButton FormButton-secondStateButton_intro"
        >
          {btnText}
        </a>
      ) : (
        <div className="FormIntroBlock-container">
          <div className="FormIntroBlock__cardInfo">
            No credit card required
          </div>
          <form className="FormIntroBlock-form">
            <input
              className={`FormIntroBlock-form__input${
                onError ? " FormIntroBlock-form__input_alert" : ""
              }`}
              type="text"
              placeholder={
                onError ||
                placeholder ||
                "Enter your email to start your FREE TRIAL!"
              }
              value={emailValue}
              onChange={onEmailChange}
              onKeyDown={onKey}
            />
            <button
              onClick={onSubmit}
              className="FormIntroBlock-form__button"
              disabled={isLoading}
            >
              {btnText}
            </button>
          </form>
          <div className="FormIntroBlock__agreeText">
            By entering your email, you agree to receive marketing emails from
            AMZScout
          </div>
        </div>
      )}
    </div>
  );
};

FormIntroBlock.propTypes = {
  emailValue: PropTypes.string,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.string,
  onKey: PropTypes.func,
  isLoading: PropTypes.bool,
  formOpacity: PropTypes.number,
  title: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormIntroBlock;
