import React from "react";
import PropTypes from "prop-types";
import { PAGE_OPTIONS, PAGES } from "../../../helpers/constants";
import Points from "./Points";
import LicenseEmailCollectContainer from "../../../containers/common/forms/LicenseEmailCollectContainer";
import Screen from "./Screen";
import "./index.scss";

const HomeIntro = ({ homeIntroRef }) => {
  return (
    <section className="PgASB-HI" ref={homeIntroRef}>
      <div className="container">
        <div className="PgASB-HI-row">
          <div className="PgASB-HI-info">
            <h2 className="PgASB-HI__h1">
              <span className="orange">
                Start Selling and Grow Your Business
              </span> with a Complete Amazon Toolset <br />
              by AMZScout
            </h2>
            <Points />
            <LicenseEmailCollectContainer
              page={PAGES.AMAZON_SELLERS_BUNDLE}
              buttonTextFirstState="Try AMZScout For Free!"
              buttonTextSecondState="Try AMZScout For Free!"
              customClass="PgASB-HI-emailCollect"
              redirectUrl={
                PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL
              }
            />
          </div>
          <Screen />
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default HomeIntro;
