import React from "react";
import "./index.scss";
import { getTrueClasses } from "../../../helpers";

const EmptySpace = ({ isShow, isDealsBundle }) => {
  if (!isShow) return null;

  return (
    <section
      className={getTrueClasses("PgASB-ES", isDealsBundle && "PgASB-ES_small")}
    />
  );
};

export default EmptySpace;
