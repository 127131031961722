import React, { useState } from "react";
import EmailCollectContainer from "./EmailCollectContainer";
import LicenseEmailCollect from "../../../components/common/Forms/LicenseEmailCollect";
import PropTypes from "prop-types";

const LicenseEmailCollectContainer = (props) => {
  const {
    page,
    buttonTextFirstState,
    buttonTextSecondState,
    customClass,
    redirectUrl,
    language = "en",
    customPlaceholder,
  } = props;
  const [getRedirectShowText, setRedirectShowText] = useState(false);

  return (
    <EmailCollectContainer
      page={page}
      onSuccess={() => setRedirectShowText(true)}
      customRedirectUrl={redirectUrl}
      language={language}
    >
      <LicenseEmailCollect
        page={page}
        buttonTextFirstState={buttonTextFirstState}
        buttonTextSecondState={buttonTextSecondState}
        isShowText={getRedirectShowText}
        customClass={customClass}
        language={language}
        customPlaceholder={customPlaceholder}
      />
    </EmailCollectContainer>
  );
};

LicenseEmailCollectContainer.propTypes = {
  page: PropTypes.string.isRequired,
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  customPlaceholder: PropTypes.string,
};

export default LicenseEmailCollectContainer;
