import React from "react";
import { PG_ASB_FORMS } from "../index.content";
import "./index.scss";

const CtaSection = ({ handleClick }) => {
  const { pic, pic2x, picMob, picMob2x, webp, webp2x, webpMob, webpMob2x } =
    PG_ASB_FORMS.form1;

  return (
    <section className="PgASB-CtaSection">
      <div className="container">
        <div className="PgASB-CtaSection-row">
          <div className="PgASB-CtaSection__pretitle">You can try</div>
          <div className="PgASB-CtaSection__title">
            TRY ALL OF THESE AMAZON TOOLS <br />
            FOR FREE NOW!
          </div>
          <div className="PgASB-CtaSection__btn" onClick={handleClick}>
            Try AMZScout For Free!
          </div>
          <div className="PgASB-CtaSection__img">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 768px)"
                srcSet={`${webp} 1x, ${webp2x} 2x`}
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${pic} 1x, ${pic2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${webpMob} 1x, ${webpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${picMob} 1x, ${picMob2x} 2x`}
              />
              <img src={pic} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
