import React from "react";
import IC_GO_ORANGE from "../../../assets/images/vector/pages/amazon-sellers-bundle/ic_go_orange.svg";
import TOOLS from "./index.content";
import ScreenFull from "../../common/UI/ScreenFull";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const Tools = () => (
  <section className="PgASB-T">
    <div className="container">
      <h1 className="PgASB-T__title">12 Best Amazon Seller Tools</h1>
      <p className="PgASB-T__subtitle">
        AMZScout provides expertise and the tools you need to sell on Amazon
      </p>
      {TOOLS.map(({ screen, title, list }, i) => (
        <div key={title} className={`PgASB-T-row PgASB-T-row_r${i + 1}`}>
          <div className="PgASB-T-info">
            <p className="PgASB-T-info__title">{title}</p>
            <ul className="PgASB-T-info-list">
              {list.map(({ link, text }) => (
                <li key={link.text} className="PgASB-T-info-item">
                  <TrackedLink
                    classes="PgASB-T-info-item__link"
                    category="LANDING|amazon-sellers-bundle"
                    action={link.text}
                    path={link.path}
                    showMobileProPopup={link.showMobileProPopup}
                  >
                    {link.text} <img src={IC_GO_ORANGE} alt="" />
                  </TrackedLink>
                  <p className="PgASB-T-info-item__text">{text}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="PgASB-T-screen">
            <ScreenFull screen={screen} customClasses="PgASB-T-screen__img" />
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Tools;
