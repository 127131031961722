const FORM_PAGE_BLOCK_CONTENT = {
  en: {
    placeholder: "example@gmail.com",
    cardInfo: "No credit card required",
    agree: "By entering your email, you agree to receive marketing emails from AMZScout"
  },
  de: {
    placeholder: "example@gmail.com",
    cardInfo: "Keine Kreditkarte nötig",
    agree: "Mit der Eingabe Ihrer E-Mail-Adresse erklären Sie sich einverstanden, Marketing-E-Mails von AMZScout zu erhalten."
  }
};

export default FORM_PAGE_BLOCK_CONTENT;
